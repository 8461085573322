import React from "react";
import {ReactState} from "../../utils/types/types";
import {Button, Modal, ModalBody, ModalHeader} from "react-bootstrap";
// @ts-ignore

import './styles.css'

import {SupportButton} from "../Svg/SupportButton/component";

export const SupportModale: React.FC<{
  supportModalState: ReactState<boolean>,
}> = ({
        supportModalState,
      }) => {

  return (

      <Modal
          show={supportModalState[0]}
          aria-labelledby="Support"
          aria-describedby="Support"
      >
        <ModalHeader>
          <div>
            <h3>
              Thank You for Downloading!
            </h3>
          </div>
        </ModalHeader>
        <ModalBody>
          <p>If you appreciate our work and would like to support us in creating more great content,
            please consider making a donation. Your contribution helps us continue to improve our
            app and bring you even better features.</p>
          <div className="support-button-container">
            <a href="https://insigh.to/b/convoclip" target="_blank" rel="noreferrer" >
              Suggest a feature/ report a problem
            </a>
            <SupportButton/>
          </div>

        </ModalBody>
        <div className="next-time">
          <Button variant="link" onClick={() => supportModalState[1](false)}>Maybe next
            time</Button>
        </div>
      </Modal>

  )
}