import './styles.css'
import {quiz} from "../../utils/quiz-form";
import {Question} from "./Question/component";
import React, {useEffect, useRef, useState} from "react";
import {captureQuizFrameLegacy, FrameType, Utils, VideoFrame} from "../../utils/capture-utils";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../store";
import {CaptureStateStatus} from "../../../store/quizStore";

export interface QuizMetadata {
  numberOfQuestions: number;
  quizRef: React.MutableRefObject<null>
}

export const ScreenApp: React.FC =
    observer(() => {

      const {quizStore} = useStores();

      const utils = new Utils(quizStore);
      const {
        currentQuestionIndex, captureState, showAnswer, setShowAnswer,
        captureNext, initCapture
      }
          = quizStore
      const quizTest = quiz;

      const videoFrames = useRef<VideoFrame[]>([]);
      const frameIndex = useRef<number>(0);
      const quizRef = useRef(null)

      const [currentQuestion, setCurrentQuestion] = useState(quizTest.sections[0].questions[0]);

      const startRecording = () => {
        initCapture({
          numberOfQuestions: 3,
          quizRef: quizRef
        })
        setCurrentQuestion(quiz.sections[0].questions[0])
      }

      useEffect(() => {
        if (captureState.getStatus() === CaptureStateStatus.QUIZ_TITLE) {
          console.log("SCREEN: status: ", captureState.getStatus())
          //TODO play quiz title
          captureNext()
        }
      }, [captureNext, captureState, captureState.status]);

      useEffect(() => {
            if (captureState.getStatus() === CaptureStateStatus.ANSWER) {
              if(showAnswer){
                utils.captureQuizFrame(FrameType.ANSWER, 1000)
              } else {
                setShowAnswer(true)
                console.log("SCREEN/ANSWER: status: ", captureState.getStatus())
                setTimeout(() => {
                  if (currentQuestionIndex + 1 < quiz.sections[0].questions.length) {
                    setCurrentQuestion(quiz.sections[0].questions[currentQuestionIndex + 1])
                  } else {
                    utils.convertFramesToVideo()
                  }
                  captureNext()
                }, 1000)
              }
            }
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps
          , [showAnswer, captureState.status, captureState, setShowAnswer, captureNext, currentQuestionIndex])

/*
      /!**
       * @Deprecated
       * **!/
      const startRecordingLegacy = () => {
        quizTest.sections.forEach(section => {
          section.questions.forEach((question, index) => {
            if (index > 0) {
              flushSync(() => {
                setCurrentQuestion(question)
                // setQuestionIndex(index + 1)
              })
            }

            // record question animation
            captureQuizFrameLegacy(FrameType.QUESTION, 3000, videoFrames.current, quizRef, ++frameIndex.current, question.text, index + 1)
            flushSync(
                () => {
                  setPlayQuestionTitle(true)
                }
            )

          })
        })
        // Move to next question
        // convertFramesToVideo(videoFrames.current)
        /!*    videoFrames.current = []
            frameIndex.current = 0
            setQuestionIndex(1)
            setTimer(1)
            setShowAnswer(false)
            setCurrentQuestion(quizTest.sections[0].questions[0])*!/
      }

      /!**
       * @Deprecated
       * **!/
      const captureAnswer = () => {
        // startTimer
        /!*        flushSync(() => {
                  setTimer(1)
                })

                // captureFrame(TIMER, 1s)
                captureQuizFrame(FrameType.TIMER, 1000, videoFrames.current, quizRef, ++frameIndex.current)
                console.log(videoFrames.current)

                // advanceTimer
                flushSync(() => {
                  setTimer(2)
                })
                // captureFrame(TIMER, 1s)
                captureQuizFrame(FrameType.TIMER_START, 1000, videoFrames.current, quizRef, ++frameIndex.current)
                console.log(videoFrames.current)

                // advanceTimer
                flushSync(() => {
                  setTimer(3)
                })

                // captureFrame(TIMER, 1s)
                captureQuizFrame(FrameType.TIMER, 1000, videoFrames.current, quizRef, ++frameIndex.current)
                console.log(videoFrames.current)

                flushSync(() => {
                  setShowAnswer(true)
                })

                captureQuizFrame(FrameType.ANSWER, 1000, videoFrames.current, quizRef, ++frameIndex.current)

                // clean timer
                flushSync(() => {
                  setTimer(1)
                  setShowAnswer(false)
                })*!/
      }

      /!**
       * @Deprecated
       * **!/
      const captureQuestionFrame = (frameDuration: number, allFramesCaptured: boolean) => {
        if (allFramesCaptured) {
          console.log('set to false !!!!')
          setPlayQuestionTitle(false)
          //captureAnswer()
        }
        captureQuizFrameLegacy(FrameType.QUESTION_ANIMATION, frameDuration, videoFrames.current, quizRef, ++frameIndex.current, currentQuestion.text, currentQuestionIndex)
      }
*/

      const download = () => {
        captureQuizFrameLegacy(FrameType.QUESTION, 3000, videoFrames.current, quizRef, -1, currentQuestion.text, 1)
        // convertFramesToVideo(videoFrames.current);
        videoFrames.current = []
        frameIndex.current = 0
      }

      return <>
        <button className={"btn btn-primary"} onClick={startRecording}>Record and download</button>
        <button className={"btn btn-primary"} onClick={download}>download</button>
        <div ref={quizRef} className={"screen-container"}>
          <Question question={currentQuestion}
          ></Question>
        </div>
      </>
    })