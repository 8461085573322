import React from "react";
import './styles.css'
import {BatteryLevel} from "./styles";

export const BatteryIcon: React.FC<{ percentage: number }> = ({percentage}) => {
  if (percentage > 100 || percentage < 0) return null

  return <>
    <div className={"battery-container"}>
      <div className={"battery-border"}>
        <BatteryLevel $percentage={percentage}></BatteryLevel>
      </div>
      <div className={"battery-pole-container"}>
        <div className={"battery-pole"}></div>
      </div>
    </div>
  </>
}