// store/RootStore.js

import TextingStore from "./textingStore";
import QuizStore from "./quizStore";

export class RootStore {
  textingStore: typeof TextingStore;
  quizStore: typeof QuizStore;

  constructor() {
    this.textingStore = TextingStore;
    this.quizStore = QuizStore;
  }
}

// Create a singleton instance
const rootStore = new RootStore()

export default rootStore