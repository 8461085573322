import './styles.css'
import {Choice} from "./styles";
import anime from 'animejs';
import {Animation} from "../../Animation/component";
import {useCallback, useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../../store";
import {CaptureStateStatus} from "../../../../store/quizStore";
import {FrameType, Utils} from "../../../utils/capture-utils";

export const Question: React.FC<{
  question: any
}> =
    observer(({
                question
              }) => {

      const {quizStore} = useStores();

      const utils = new Utils(quizStore);

      const {
        currentQuestionIndex,
        totalQuestions,
        captureState,
        showAnswer,
        timer,
        setTimer,
        captureNext,
        videoFrames
      } = quizStore

      const [playAnimation, setPlayAnimation] = useState(false)

      const recordAnimation =
          useCallback((): Promise<void> => {
            return new Promise((resolve) => {
              var timeline = anime.timeline({
                autoplay: false, // Don't autoplay, control manually
                /*            complete: (() => {
                              resolve()
                            })*/
              })
              timeline.add({
                targets: '#timer',
                autoplay: false,
                loop: true,
                rotate: {
                  value: '+=2turn', // 0 + 2 = '2turn'
                  duration: 3000,
                  easing: 'easeInOutSine'
                },
                direction: 'alternate'
              }, '-=0')

              timeline.add({
                autoplay: false,
                targets: '.timer-progressbar',
                width: '100%', // -> from '28px' to '100%',
                easing: 'easeInOutQuad',
                direction: 'alternate',
                duration: 3000,
                loop: false
              }, '-=3000')

              const duration = timeline.duration / 2
              console.log('duration', duration)
              utils.captureAnim(timeline, FrameType.TIMER_START).then(() => {
                // utils.convertFramesToVideo()
                resolve();
              })
              /*              const capture = (time: number) => {
                              console.log('capture timer:', time)
                              const FPS = 60
                              const interval = Math.round(1000 / FPS)

                              if (time >= 3000) {
                                timeline.seek(time)
                                captureQuizFrame(FrameType.TIMER, interval)
                                console.log(videoFrames)
                                // resolve()
                                return;
                              }
                              timeline.seek(time)
                              captureQuizFrame(FrameType.TIMER, interval)

                              setTimeout(() => {
                                    capture(time + interval)
                                  }, interval
                              )
                            }

                            capture(0)*/
              // timeline.restart()
            })
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [])

      useEffect(() => {
        console.log("video changed", videoFrames.length)
      }, [videoFrames]);

      useEffect(() => {
        setTimer(0)
        if (timer > -1 && captureState.getStatus() === CaptureStateStatus.TIMER) {
          console.log("TIMER: use effect !!!", captureState.getStatus())
          setTimeout(() => {
          }, 3000)

          recordAnimation().then(() => {
            console.log("timer finished")
            captureNext()
          })
        }
      }, [captureNext, captureState, captureState.status, recordAnimation, setTimer, timer]);

      return <div className={"custom-container"}>
        <div className={"title"}>
          <h3 className={"poetsen-one"}>{`${currentQuestionIndex + 1}/${totalQuestions}`}
          </h3>
          <div className={"anim-container"}>
            <Animation text={question.text}
                       playAnimationState={[playAnimation, setPlayAnimation]}></Animation>
          </div>
        </div>
        <div className={"questions"}>
          {question.options.map((option: any, index: number) => (
              <Choice key={index} $isCorrect={option.correct} $showAnswer={showAnswer}>
                {`${index + 1}) ${option.value}`}
              </Choice>
          ))}
        </div>
        {timer > -1 && (
            <div className={"counter px-4"}>
              <div className={"timer-progressbar-container me-3"}>
                <div className={"timer-progressbar"}></div>
              </div>
              <img className={"timer"} id={'timer'} width={"25%"} height={"100%"}
                   src={require('../../../assets/img.png')} alt={'timer'}/>
            </div>
        )}
      </div>
    })