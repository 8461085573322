import styled, {css} from "styled-components";

export const BatteryLevel = styled.div<{
  $percentage: number
}>`${props =>
    css`
      width: ${props.$percentage}%;
      height: 11px;
      background-color: ${props.$percentage > 25 ? 'white' : 'red'};
      border-radius: 2px 0 0 2px;
    `}
`