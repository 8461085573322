const enum DIFFICULTY {
  EASY,
  MEDIUM,
  HARD,
  EXPERT
}

const enum QUIZ_TYPE {
  QCM,
  QUESS,
}

export const quiz = {
  title: "",
  sections: [
    {
      difficulty: DIFFICULTY.EASY,
      type: QUIZ_TYPE.QCM,
      questions: [
        {
          image: "",
          text: "What is the official currency of the united states ?",
          options: [
            {value: "Euro", correct: false},
            {value: "USD", correct: true},
            {value: "Pound", correct: false}
          ],
        },
        {
          image: "",
          text: "What is the capital city of australia?",
          options: [
            {value: "Sydney", correct: false},
            {value: "Canberra", correct: true},
            {value: "Melbourne", correct: false}
          ],
        },
        {
          image: "",
          text: "In which year did the titanic sank",
          options: [
            {value: "1852", correct: false},
            {value: "1912", correct: true},
            {value: "1968", correct: false}],
        },
      ]
    }
  ]
}