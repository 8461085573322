// Routes.tsx
import React from 'react';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import {QuizApp} from "./QuizApp/Components/app/component";
import ChatApp from "./ChatApp/Components/ChatApp/component";
import {estEnvLocal} from "./env_utils";

export const RoutesApp: React.FC = () => {
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to={"/texting-story"} replace/>}></Route>
          <Route path="/texting-story" element={<ChatApp/>}/>
          {estEnvLocal() && <Route path="/quiz" element={<QuizApp/>}/>}
          <Route path="*" element={<div>not found</div>}/>
        </Routes>
      </BrowserRouter>
  );
};

export default Routes;