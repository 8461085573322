// src/stores/index.ts
import React from "react";
import {RootStore} from "./rootStore";

const store = new RootStore();

export const StoreContext = React.createContext(
  store);

export const useStores = () => React.useContext(StoreContext);
