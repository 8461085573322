import {makeAutoObservable} from "mobx";
import {QuizMetadata} from "../QuizApp/Components/Screen/component";
import {VideoFrame} from "../QuizApp/utils/capture-utils";
import React from "react";

export enum CaptureStateStatus {
  QUIZ_TITLE,
  QUESTION_TITLE,
  TIMER,
  ANSWER,
  IDLE
}

class CaptureState {
  status: CaptureStateStatus;

  constructor() {
    this.status = CaptureStateStatus.IDLE;
    makeAutoObservable(this)
  }

  getStatus = (): CaptureStateStatus => {
    return this.status;
  }

  setStatus = (status: CaptureStateStatus) => {
    this.status = status
  }

  public next = (questionIndex: number, totalQuestions: number) => {
    switch (this.status) {
      case CaptureStateStatus.IDLE:
        this.status = CaptureStateStatus.QUIZ_TITLE;
        break;
      case CaptureStateStatus.QUIZ_TITLE:
        this.status = CaptureStateStatus.QUESTION_TITLE;
        break;
      case CaptureStateStatus.QUESTION_TITLE:
        this.status = CaptureStateStatus.TIMER;
        break;
      case CaptureStateStatus.TIMER:
        this.status = CaptureStateStatus.ANSWER;
        break;
      case CaptureStateStatus.ANSWER: {
        if (questionIndex === totalQuestions) {
          this.status = CaptureStateStatus.IDLE;
          break;
        } else {
          this.status = CaptureStateStatus.QUESTION_TITLE;
          break;
        }
      }
    }
  }
}

export class QuizStore {
  // Quiz
  videoFrames: VideoFrame[] = [];
  totalQuestions = 3;
  quizRef: React.MutableRefObject<null> | undefined

  // Variable
  currentQuestionIndex = 0;
  showAnswer = false;
  frameIndex = 0;
  timer = -1;
  captureState: CaptureState = new CaptureState();

  constructor() {
    makeAutoObservable(this);
  }

  getCurrentQuestionIndex = () => {
    return this.currentQuestionIndex;
  }

  setTotalQuestions = (number: number) => {
    this.totalQuestions = number;
  }

  captureNext = () => {
    if (this.currentQuestionIndex + 1 < this.totalQuestions
        && this.captureState.getStatus() === CaptureStateStatus.ANSWER) {
      this.currentQuestionIndex++;
      this.showAnswer = false;
      this.timer = -1;
      this.captureState.next(this.currentQuestionIndex, this.totalQuestions);
    } else {
      this.captureState.next(this.currentQuestionIndex + 1, this.totalQuestions);
    }
  }

  setTimer = (time: number) => {
    this.timer = time
  }

  setShowAnswer = (show: boolean) => {
    this.showAnswer = show;
  }

  initCapture = (metadata?: QuizMetadata) => {
    if (metadata) {
      this.totalQuestions = metadata.numberOfQuestions;
      this.quizRef = metadata.quizRef;
    }
    this.videoFrames = []
    this.currentQuestionIndex = 0
    this.timer = -1
    this.showAnswer = false
    this.captureState.setStatus(CaptureStateStatus.QUIZ_TITLE)
  }

  upgetFrameIndex = (): number => {
    this.frameIndex++;
    return this.frameIndex
  }

  pushFrame = (frame: VideoFrame) => {
    this.videoFrames.push(frame)
  }
}
const quizStore = new QuizStore();
export default quizStore