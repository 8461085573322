import {Message, StorableMessage} from "../types/types";
import {toSessionState, toStorableSessionState} from "../../mappers/session-state-mapper";
import {estEnvLocal} from "../../../env_utils";

let db: IDBDatabase;

export interface SessionState {
  id: string;
  receiversName: string;
  profilePicture: Blob | null;
  showHeader: boolean;
  showBatteryPercentage: boolean;
  hour24: boolean;
  network: string;
  phoneTime: string;
  messages: Message[]
}

export interface StorableSessionState {
  id: string;
  receiversName: string;
  profilePicture: string | null;
  showHeader: boolean;
  showBatteryPercentage: boolean;
  network: string;
  phoneTime: string;
  messages: StorableMessage[]
}

const initDB = (): Promise<IDBDatabase> => {
  return new Promise((resolve, reject) => {
    if (db) {
      resolve(db)
      return;
    }
    const request = indexedDB.open("convoclipDB", 1);

    request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
      db = (event.target as IDBOpenDBRequest).result;
      if (!db.objectStoreNames.contains("sessions")) {
        db.createObjectStore("sessions", {keyPath: "id", autoIncrement: true});
      }
    };

    request.onsuccess = (event) => {
      db = (event.target as IDBOpenDBRequest).result;
      resolve(db);
    };

    request.onerror = (event) => {
      console.error('IndexedDB error:', (event.target as IDBOpenDBRequest).error?.message);
      reject((event.target as IDBOpenDBRequest).error?.message);
    };
  });
}

export const storeSessionState = (sessionState: SessionState) => {
  initDB().then(cdb => {
    toStorableSessionState(sessionState).then(storableSessionState => {
      const transaction = db.transaction("sessions", "readwrite");
      const objectStore = transaction.objectStore("sessions");

      const request = objectStore.put(storableSessionState);

      request.onsuccess = () => {
        console.log("SessionState stored successfully");
      };

      request.onerror = (event: Event) => {
        console.error("Error storing SessionState: ", (event.target as IDBRequest).error?.message, sessionState);
      };
    })
  })

}

export const defaultSession: SessionState = {
  id: "session-id",
  receiversName: 'John Doe',
  messages: [],
  showHeader: true,
  showBatteryPercentage: true,
  hour24: false,
  phoneTime: '15:08',
  network: '5G',
  profilePicture: null
}


export const retrieveSessionState = (): Promise<SessionState> => {
  return new Promise((resolve, reject) => {
    initDB().then(db => {
      const transaction = db.transaction("sessions");
      const objectStore = transaction.objectStore("sessions");
      const request = objectStore.get("session-id");

      request.onsuccess = (event: Event) => {
        const sessionState: StorableSessionState | undefined = (event.target as IDBRequest).result;
        if (sessionState) {
          if(estEnvLocal()){
            console.log("SessionState retrieved successfully");
          }
          // Here, you resolve the promise with the sessionState object
          resolve(toSessionState(sessionState));
        } else {
          console.log("No SessionState found with the id 'session-id'",);
          // Resolve with undefined if no sessionState is found
          resolve(defaultSession);
        }
      };
    })
  });
};

