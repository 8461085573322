import './styles.css'
import anime from 'animejs';
import {ReactState} from "../../../ChatApp/utils/types/types";
import {useCallback, useEffect} from "react";
import {observer} from "mobx-react-lite";
import {CaptureStateStatus} from "../../../store/quizStore";
import {useStores} from "../../../store";
import {Utils} from "../../utils/capture-utils";
import {FrameType} from "../../utils/capture-utils";

export const Animation: React.FC<{
  text: string,
  playAnimationState: ReactState<boolean>
}> =
    observer(({text, playAnimationState}) => {

      const {quizStore} = useStores();

      const utils = new Utils(quizStore)

      const {
        captureState,
        captureNext
      } = quizStore

      const recordAnimation = useCallback(
          (): Promise<void> => {
            const words = text.split(' ')
            return new Promise(
                (resolve) => {
                  let timeline = anime.timeline({
                    loop: false, autoplay: false,
                  /*  complete: () => {
                      resolve();
                    }*/
                  })
                  words.forEach((word, index) => {
                    timeline.add({
                      targets: `.ml4 .letters-${index + 1}`,
                      opacity: ml4.opacityIn,
                      scale: ml4.scaleIn,
                      duration: ml4.durationIn
                    }).add({
                      targets: `.ml4 .letters-${index + 1}`,
                      opacity: 0,
                      scale: ml4.scaleOut,
                      duration: ml4.durationOut,
                      easing: "easeInExpo",
                      delay: ml4.delay
                    })
                  })
                  utils.captureAnim(timeline, FrameType.QUESTION_ANIMATION).then(() => {
                    resolve()
                  })
                  // anim.restart();
                }
            )
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [])

      useEffect(() => {
        if (captureState.getStatus() === CaptureStateStatus.QUESTION_TITLE) {
          console.log("TITLE: capture question (1)", captureState.getStatus())
          recordAnimation().then(() => {
                captureNext()
              }
          )
        }
      }, [captureNext, captureState, captureState.status, recordAnimation]);

      useEffect(() => {
        if (playAnimationState[0]) {
          recordAnimation()
          playAnimationState[1](false)
        }
      }, [playAnimationState, recordAnimation]);

      var ml4 = {
        opacityIn: [0, 1],
        scaleIn: [0.2, 1],
        scaleOut: 3,
        durationIn: 100,
        durationOut: 75,
        delay: 62.5
      };

      return <>
        <h2 className={"ml4 poesten-one"}>
          {text.split(' ').map((word, index) => (
              <span key={index} className={`letters letters-${index + 1}`}>{word}</span>
          ))}
        </h2>
      </>
    })