import React from "react";
import './styles.css'

export const BottomComponent: React.FC = () => {
  return <>
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="footer-col">
              <h4>About ConvoClip</h4>
              <p>ConvoClip is an app that allows you to make texting stories online and for free.</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="footer-col middle">
              <h4>Important Links</h4>
              <ul className="list-unstyled li-space-lg">
                <li className="media">
                  <i className="fas fa-square"></i>
                  <div className="media-body">
                    <p>Visit our website
                      <a className="turquoise" href="https://convoclip.com"> convoclip.com</a>
                    </p>
                  </div>
                </li>
                <li className="media">
                  <i className="fas fa-square"></i>
                  <div className="media-body"><p>Read our
                    <a className="turquoise"
                       href="https://convoclip.com/terms-conditions.html" target="_blank"
                       rel="noreferrer"> Terms
                      & Conditions</a>,
                    <a className="turquoise"
                       href="https://convoclip.com/privacy-policy.html" target="_blank"
                       rel="noreferrer"> Privacy
                      Policy</a></p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-4">
            <div className="footer-col last">
              <h4>Social Media</h4>
              <span className="fa-stack">
                            <a href="https://facebook.com/convoclip" target={"_blank"}
                               rel="noreferrer">
                                <i className="fas fa-circle fa-stack-2x"></i>
                                <i className="fab fa-facebook-f fa-stack-1x"></i>
                            </a>
                        </span>
              <span className="fa-stack">
                            <a href="https://twitter.com" target={"_blank"} rel="noreferrer">
                                <i className="fas fa-circle fa-stack-2x"></i>
                                <i className="fab fa-twitter fa-stack-1x"></i>
                            </a>
                        </span>
              <span className="fa-stack">
                            <a href="https://google.com" target={"_blank"} rel="noreferrer">
                                <i className="fas fa-circle fa-stack-2x"></i>
                                <i className="fab fa-google-plus-g fa-stack-1x"></i>
                            </a>
                        </span>
              <span className="fa-stack">
                            <a href="https://instagram.com" target={"_blank"} rel="noreferrer">
                                <i className="fas fa-circle fa-stack-2x"></i>
                                <i className="fab fa-instagram fa-stack-1x"></i>
                            </a>
                        </span>
              <span className="fa-stack">
                            <a href="https://linkedin.com" target={"_blank"} rel="noreferrer">
                                <i className="fas fa-circle fa-stack-2x"></i>
                                <i className="fab fa-linkedin-in fa-stack-1x"></i>
                            </a>
                        </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
}